import React, { useEffect, useState, Fragment, useCallback } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  Spinner,
  Label,
  Input,
  CardBody,
  Button,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import { Link } from "react-router-dom";
import InputMask from "react-input-mask";
import Select from "react-select";
import {
  checkLastLogin,
  formatEmail,
  nomeMask,
  trataUsename,
} from "../../Components/FunctionsRepository";
import { toast } from "react-toastify";

import { APIClient, getLoggedinUser } from "../../helpers/api_helper";

const apiClient = new APIClient();

const NovoUsuario = () => {
  document.title = "Nuevo Usuario | Nautt Finance";

  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [nome, setNome] = useState("");
  const [login, setLogin] = useState("");
  const [email, setEmail] = useState("");
  const [chavePix, setChavePix] = useState("");
  const [telefone, setTelefone] = useState("");
  const [paisTelefone, setPaisTelefone] = useState("BR");
  const [tipo, setTipo] = useState("lojista");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [funcoesSelecionadas, setFuncoesSelecionadas] = useState([]);
  const [optionsFuncoes, setOptionsFuncoes] = useState([]);
  const [userLogado, setUserLogado] = useState({});

  const selecionarTodasFuncoes = () => {
    setFuncoesSelecionadas(optionsFuncoes);
  };

  const deselecionarTodasFuncoes = () => {
    setFuncoesSelecionadas([]);
  };

  const criar = async () => {
    setLoading(true);

    const funcoesIds = funcoesSelecionadas.map((funcao) => funcao.value);

    const data = {
      nome: nomeMask(nome),
      username: login,
      email,
      telefone: telefone.replace(/\D/g, ""),
      pais_telefone: paisTelefone,
      tipo,
      status: "ativo",
      chave_pix: chavePix,
      password,
      password_confirmation: confirmPassword,
      funcoes: funcoesIds,
    };

    const response = await apiClient.post("/usuarios", data);
    if (response.sucesso) {
      toast.success(`${response.mensagem}. Redirigiendo...`);
      setTimeout(() => {
        window.location.href = "/usuarios";
      }, 1000);
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  const fetchFuncoes = useCallback(async () => {
    setLoadingPage(true);
    const response = await apiClient.get("/funcoes");
    if (response.sucesso) {
      const dados = response.dados;
      setOptionsFuncoes(
        dados.map((funcao) => ({
          label: `${funcao?.funcao}`,
          value: `${funcao?.id}`,
        }))
      );
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoadingPage(false);
  }, []);

  const handleCheckboxChange = (funcao) => {
    if (funcoesSelecionadas.includes(funcao)) {
      setFuncoesSelecionadas(funcoesSelecionadas.filter((f) => f !== funcao));
    } else {
      setFuncoesSelecionadas([...funcoesSelecionadas, funcao]);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setUserLogado(await getLoggedinUser());
      await fetchFuncoes();
    };

    fetchData();
    setLoading(false);
  }, [loading]);

  return (
    <Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Registro de Usuarios" pageTitle="Usuarios" />
          <Card>
            {!loadingPage && userLogado.tipo === "admin" && (
              <CardBody>
                <div className="mt-4">
                  <Row className="mt-3">
                    <Col md="6">
                      <div>
                        <Label htmlFor="nome" className="form-label">
                          Nombre <span className="text-danger">*</span>
                        </Label>
                        <Input
                          id="nome"
                          className="form-control"
                          type="text"
                          placeholder="Nombre"
                          value={nome}
                          onChange={(e) => setNome(e.target.value)}
                          maxLength={150}
                        />
                      </div>
                    </Col>

                    <Col md="6">
                      <div>
                        <Label htmlFor="email" className="form-label">
                          Correo electrónico
                        </Label>
                        <Input
                          id="email"
                          className="form-control"
                          type="email"
                          placeholder="Correo electrónico"
                          value={email}
                          onChange={(e) =>
                            setEmail(formatEmail(e.target.value))
                          }
                          maxLength={150}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md="4">
                      <div>
                        <Label htmlFor="pais_telefone" className="form-label">
                          País del Teléfono
                        </Label>
                        <Input
                          type="select"
                          id="pais_telefone"
                          value={paisTelefone}
                          onChange={(e) => setPaisTelefone(e.target.value)}
                          className="form-control"
                        >
                          <option value="BR">Brasil (+55)</option>
                          <option value="AR">Argentina (+54)</option>
                        </Input>
                      </div>
                    </Col>

                    <Col md="4">
                      <div>
                        <Label htmlFor="telefone" className="form-label">
                          Teléfono
                        </Label>
                        <InputMask
                          mask={
                            paisTelefone === "BR"
                              ? "(99) 99999-9999"
                              : "999 999-9999"
                          }
                          placeholder={
                            paisTelefone === "BR"
                              ? "(99) 99999-9999"
                              : "999 999-9999"
                          }
                          defaultValue={telefone}
                          onChange={(e) => setTelefone(e.target.value)}
                        >
                          {(inputProps) => (
                            <Input
                              type="text"
                              className="form-control"
                              {...inputProps}
                            />
                          )}
                        </InputMask>
                      </div>
                    </Col>

                    <Col md="4">
                      <div>
                        <Label htmlFor="login" className="form-label">
                          Usuario <span className="text-danger">*</span>
                        </Label>
                        <Input
                          id="login"
                          className="form-control"
                          type="text"
                          placeholder="Usuario"
                          value={login}
                          onChange={(e) =>
                            setLogin(trataUsename(e.target.value))
                          }
                          maxLength={30}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md="6">
                      <div>
                        <Label htmlFor="password" className="form-label">
                          Contraseña
                        </Label>
                        <Input
                          id="password"
                          className="form-control"
                          type="password"
                          placeholder="Contraseña"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          maxLength={20}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div>
                        <Label htmlFor="confirmPassword" className="form-label">
                          Confirmar Contraseña
                        </Label>
                        <Input
                          id="confirmPassword"
                          className="form-control"
                          type="password"
                          placeholder="Confirmar Contraseña"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          maxLength={20}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col md="6">
                      <Label htmlFor="chavePix" className="form-label">
                        Clave Pix
                      </Label>
                      <Input
                        id="chavePix"
                        className="form-control"
                        type="text"
                        placeholder="Clave Pix"
                        value={chavePix}
                        onChange={(e) => setChavePix(e.target.value)}
                        maxLength={200}
                      />
                    </Col>
                    <Col md="6">
                      <Label htmlFor="tipo" className="form-label">
                        Tipo
                      </Label>
                      <Input
                        type="select"
                        id="tipo"
                        value={tipo}
                        onChange={(e) => setTipo(e.target.value)}
                        className="form-control"
                      >
                        {/* <option value="usuario">Usuario</option> */}
                        <option value="admin">Administrador</option>
                        <option value="lojista">Comerciante</option>
                      </Input>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Label htmlFor="funcoes" className="form-label">
                      Funciones
                    </Label>

                    <Row className="mt-3">
                      <Col md="6">
                        <Button
                          color="primary"
                          className="btn btn-sm"
                          onClick={() => selecionarTodasFuncoes()}
                          disabled={loading}
                        >
                          Seleccionar todas
                        </Button>
                        <Button
                          color="secondary"
                          className="btn btn-sm ms-2"
                          onClick={() => deselecionarTodasFuncoes()}
                          disabled={loading}
                        >
                          Desmarcar todas
                        </Button>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      {optionsFuncoes.map((funcao) => (
                        <Col md="3" key={funcao.value}>
                          <div className="form-check">
                            <Input
                              type="checkbox"
                              id={`funcao-${funcao.value}`}
                              checked={funcoesSelecionadas.includes(funcao)}
                              onChange={() => handleCheckboxChange(funcao)}
                              disabled={loading}
                            />
                            <Label
                              htmlFor={`funcao-${funcao.value}`}
                              className="form-check-label"
                            >
                              {funcao.label}
                            </Label>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </Row>

                  <div className="hstack gap-2 justify-content-end mt-5">
                    <Link
                      to="#"
                      className="btn btn-link link-secondary fw-medium"
                      onClick={() => toggle()}
                      disabled={loading}
                    >
                      <i className="ri-close-line me-1 align-middle"></i> Cerrar
                    </Link>
                    <button
                      className="btn btn-secondary"
                      onClick={() => criar()}
                      disabled={loading}
                    >
                      Crear
                    </button>
                  </div>
                </div>
              </CardBody>
            )}
          </Card>
        </Container>
      </div>
    </Fragment>
  );
};

export default NovoUsuario;
