import React from "react";
import {
  Card,
  CardHeader,
  Col,
} from "reactstrap";
import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";
import ReactApexChart from "react-apexcharts";

const StoreVisits = () => {
  const dataColors =
    '["--vz-primary", "--vz-success", "--vz-warning", "--vz-danger", "--vz-info"]';
  var chartDonutBasicColors = getChartColorsArray(dataColors);

  const series = [44, 55, 41, 17, 15];
  var options = {
    labels: ["Anna", "Manuel", "Juraci", "Michael", "Don Ramón"],
    chart: {
      height: 333,
      type: "donut",
    },
    legend: {
      position: "bottom",
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      dropShadow: {
        enabled: false,
      },
    },
    colors: chartDonutBasicColors,
  };
  return (
    <React.Fragment>
      <Col xl={4}>
        <Card className="card-height-100">
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Atendentes</h4>
            <div className="flex-shrink-0">
              <button
                type="button"
                className="btn btn-soft-success btn-sm shadow-none"
              >
                <i className="ri-file-list-3-line align-middle"></i> Generar
                Informe
              </button>
            </div>
          </CardHeader>
          <ReactApexChart
            dir="ltr"
            options={options}
            series={series}
            type="donut"
            height="333"
            className="apex-charts"
          />
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default StoreVisits;
