import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardText,
  Input,
  Row,
  Col,
  Label,
  Spinner,
} from "reactstrap";
import avatar1 from "../../assets/images/logo-sm-1.png";
import {
  formatCurrency,
  checkLastLogin,
} from "../../Components/FunctionsRepository";
import { NumericFormat } from "react-number-format";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import QRCode from "qrcode.react";
import { APIClient, getLoggedinUser } from "../../helpers/api_helper";
import FeatherIcon from "feather-icons-react";

import brFlag from "../../assets/images/flags/br.svg"; //brasil
import arFlag from "../../assets/images/flags/ar.svg"; //argentina
import usFlag from "../../assets/images/flags/us.svg"; //estados unidos
import clFlag from "../../assets/images/flags/cl.svg"; //chile
import peFlag from "../../assets/images/flags/pe.svg"; //peru
import coFlag from "../../assets/images/flags/co.svg"; //colombia

const apiClient = new APIClient();

const Calculadora = () => {
  const [loading, setLoading] = useState(false);

  const [total, setTotal] = useState("");
  const [moeda, setMoeda] = useState("BRL");
  const [cotacao, setCotacao] = useState(0);
  const [totalBrl, setTotalBrl] = useState(0);
  const [qrCode, setQrCode] = useState("");
  const [cpf, setCpf] = useState("");
  const [ordem, setOrdem] = useState(null);

  useEffect(() => {
    let interval;
    if (qrCode !== "") {
      interval = setInterval(() => {
        refreshOrdemData(ordem);
      }, 2000);
    }
    return () => clearInterval(interval);
  }, [qrCode, ordem]);

  const handleButtonClick = (value) => {
    setTotal((prevTotal) => prevTotal + value);
  };

  const handleClear = () => {
    setTotal("");
    setMoeda("BRL");
    setTotalBrl(0);
    setCotacao(0);
    setQrCode("");
    setOrdem(null);
    setCpf("");
  };

  const handleBackspace = () => {
    setTotal((prevTotal) => prevTotal.slice(0, -1));
  };

  const handleCotar = async () => {
    setLoading(true);
    const data = {
      moeda,
      total,
    };

    const response = await apiClient.post("/cotar", data);
    if (response.sucesso) {
      const dados = response.dados;
      setCotacao(dados.cotacao_exchange);
      setTotalBrl(dados.total_brl);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoading(false);
  };

  const refreshOrdemData = async (ordem) => {
    const params = {
      ordem_id: ordem.id,
    };
    const response = await apiClient.get(`/ordem`, params);
    if (response.sucesso) {
      setOrdem(response.dados);
      if (response.dados.status !== "novo") {
        setQrCode(""); // This will stop the polling
      }
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  };

  const handleGerarQrCode = async () => {
    setLoading(true);
    // const cpfValidado = cpf.replace(/\D/g, "");

    // if (cpfValidado.length !== 11) {
    //   toast.error("CPF inválido");
    //   return;
    // }

    const data = {
      total_brl: parseFloat(totalBrl).toFixed(2),
      expiracao: 60 * 60,
    };

    const response = await apiClient.post("/gerar-qr", data);
    if (response.sucesso) {
      toast.success("QR Code generado con éxito");
      setQrCode(response.dados.qrcode);
      setOrdem(response.dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoading(false);
  };

  const renderContent = () => {
    if (cotacao === 0) {
      return (
        <>
          <CardBody className="mt-4">
            <img
              aria-hidden="true"
              alt="User Avatar"
              src={avatar1}
              className="rounded-circle mb-2"
              width={100}
            />
            <CardText tag="h4" className="font-weight-bold mt-4">
              Ingrese el total a cobrar
            </CardText>
            <Row className="mt-4">
              <Col xs="12" sm="6">
                <div className="input-group input-group-lg">
                  <NumericFormat
                    id="preco"
                    placeholder={
                      moeda === "BRL"
                        ? "R$ 0,00"
                        : moeda === "USD"
                        ? "$ 0,00"
                        : "ARS 0,00"
                    }
                    className="form-control mt-2 p-2 border rounded text-center h2"
                    value={total}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    prefix={
                      moeda === "BRL" ? "R$ " : moeda === "USD" ? "$ " : "ARS "
                    }
                    fixedDecimalScale={true}
                    allowNegative={false}
                    readOnly
                  />
                </div>
                <small className="text-muted text-center">
                  El valor será cotizado en BRL y USD.
                </small>
              </Col>
              <Col xs="12" sm="6">
                <div className="input-group input-group-lg">
                  <Input
                    type="select"
                    className="form-select mt-2 p-2 border rounded text-center h2"
                    value={moeda}
                    onChange={(e) => setMoeda(e.target.value)}
                  >
                    <option value="BRL">BRL</option>
                    <option value="USD">USD</option>
                    <option value="ARS">ARS</option>
                  </Input>
                </div>
              </Col>
            </Row>

            <Row className="mt-4">
              {[
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                ",",
                "0",
                ",00",
              ].map((num) => (
                <Col xs="4" className="p-1" key={num}>
                  <Button
                    color="light"
                    className="btn btn-lg w-100 p-4 rounded"
                    onClick={() => handleButtonClick(num)}
                  >
                    {num}
                  </Button>
                </Col>
              ))}
            </Row>

            <Row className="mt-4">
              <Col xs="12" sm="4" className="p-1">
                <Button
                  color="light"
                  className="btn btn-lg w-100 p-2 rounded"
                  onClick={handleBackspace}
                >
                  Borrar
                </Button>
              </Col>
              <Col xs="12" sm="4" className="p-1">
                <Button
                  color="danger"
                  className="btn btn-lg w-100 p-2 rounded"
                  onClick={() => handleClear()}
                  disabled={total === "" || loading}
                >
                  Limpiar
                </Button>
              </Col>
              <Col xs="12" sm="4" className="p-1">
                <Button
                  onClick={() => handleCotar()}
                  color="success"
                  className="btn btn-lg w-100 p-2 rounded"
                  disabled={total === "" || loading}
                >
                  {loading ? <Spinner size="sm" color="light" /> : "Cotizar"}
                </Button>
              </Col>
            </Row>
          </CardBody>
        </>
      );
    } else if (
      ordem &&
      (ordem.status === "pago" || ordem.status === "invalido")
    ) {
      return (
        <CardBody>
          <div
            className={`rounded-circle p-3 mb-2 ${
              ordem.status === "pago" ? "bg-success" : "bg-danger"
            }`}
            style={{ width: "100px", height: "100px", margin: "0 auto" }}
          >
            <FeatherIcon
              icon={ordem.status === "pago" ? "check-circle" : "x-circle"}
              className="text-white"
              size={70}
            />
          </div>
          <CardText tag="h4" className="font-weight-bold mt-4">
            {ordem.status === "pago" ? "Pago Recibido" : "Orden Inválida"}
          </CardText>
          <CardText>
            {ordem.status === "pago"
              ? "El pago se ha procesado con éxito."
              : "La orden se consideró inválida. Por favor, inténtelo de nuevo."}
          </CardText>
          <Button
            color="primary"
            className="btn btn-lg mt-4"
            onClick={() => handleClear()}
          >
            Nueva Transacción
          </Button>
        </CardBody>
      );
    } else {
      return (
        <CardBody>
          <img
            aria-hidden="true"
            alt="User Avatar"
            src={avatar1}
            className="rounded-circle mb-2"
            width={100}
          />
          <CardText tag="h4" className="font-weight-bold mt-4">
            {formatCurrency(totalBrl)}
          </CardText>
          {/* qrCode === "" ? (
            <Row className="mt-4 d-flex justify-content-center">
              <Col xs="12" sm="10" md="8">
                <div>
                  <Label htmlFor="cpf" className="form-label text-left">
                    CPF
                  </Label>
                  <InputMask
                    mask="999.999.999-99"
                    placeholder="000.000.000-00"
                    value={cpf || ""}
                    onChange={(e) => setCpf(e.target.value)}
                  >
                    {(inputProps) => (
                      <Input
                        type="text"
                        className="form-control"
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                  <small className="text-muted">
                    Ingrese el CPF para generar el QR Code
                  </small>
                </div>
              </Col>
            </Row>
          ) : ( */}
          {qrCode !== "" && (
            <Row className="mt-4 d-flex justify-content-center">
              <Col
                xs="12"
                sm="10"
                md="8"
                className="d-flex justify-content-center"
              >
                <QRCode
                  value={qrCode}
                  size={220}
                  style={{ backgroundColor: "white" }}
                  className="p-2"
                />
              </Col>
            </Row>
          )}

          <Row className="mt-4 d-flex justify-content-center">
            <Col xs="12" sm="6" className="p-1">
              <Button
                color="light"
                className="btn btn-lg w-100 p-2 rounded"
                onClick={() => handleClear()}
                disabled={qrCode !== ""}
              >
                {qrCode === "" ? (
                  "Volver"
                ) : (
                  <>
                    <Spinner size="sm" color="light" /> Esperando...
                  </>
                )}
              </Button>
            </Col>
            {qrCode === "" && (
              <Col xs="12" sm="6" className="p-1">
                <Button
                  onClick={() => handleGerarQrCode()}
                  color="success"
                  className="btn btn-lg w-100 p-2 rounded"
                  disabled={
                     loading
                  }
                >
                  {loading ? (
                    <Spinner size="sm" color="light" />
                  ) : (
                    <>
                      <i className="mdi mdi-qrcode"></i> Generar 
                    </>
                  )}
                </Button>
              </Col>
            )}
          </Row>
        </CardBody>
      );
    }
  };

  return (
    <Card className="text-center p-4 rounded-lg shadow-md">
      {renderContent()}
    </Card>
  );
};

export default Calculadora;
