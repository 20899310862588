import React, { useState } from "react";
import {
  Row,
  Col,
  CardBody,
  CardText,
  Card,
  Container,
  Form,
  Input,
  Label,
  FormFeedback,
  Button,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import FeatherIcon from "feather-icons-react";

import logoLight from "../../assets/images/logo-light.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { APIClient } from "../../helpers/api_helper";

const RegisterKyc = () => {
  document.title = "Onboarding Persona Física | Nautt Finance";
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);
  const [politicaChecked, setPoliticaChecked] = useState(false);

  const [formData, setFormData] = useState({
    nome: "",
    sobrenome: "",
    dataNascimento: "",
    email: "",
    paisFiscal: "",
    documentoFiscal: "",
    paisTelefone: "",
    telefone: "",
    endereco: "",
    cidade: "",
    estado: "",
    paisEndereco: "",
    cep: "",
    paisDocumento: "Argentina",
    tipoDocumento: "",
    tipoComprovante: "",
  });

  const [documentos, setDocumentos] = useState({
    frente: null,
    verso: null,
    comprovante: null,
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e, docType) => {
    setDocumentos({ ...documentos, [docType]: e.target.files[0] });
  };

  const validateForm = () => {
    let tempErrors = {};
    if (!formData.nome) tempErrors.nome = "El nombre es obligatorio";
    if (!formData.sobrenome)
      tempErrors.sobrenome = "El apellido es obligatorio";
    if (!formData.dataNascimento)
      tempErrors.dataNascimento = "La fecha de nacimiento es obligatoria";
    if (!formData.email)
      tempErrors.email = "El correo electrónico es obligatorio";
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) {
      tempErrors.email = "Dirección de correo electrónico inválida";
    }
    if (!formData.paisFiscal)
      tempErrors.paisFiscal = "El país del documento es obligatorio";
    if (!formData.documentoFiscal)
      tempErrors.documentoFiscal = "El documento fiscal es obligatorio";
    if (!formData.paisTelefone)
      tempErrors.paisTelefone = "El país del teléfono es obligatorio";
    if (!formData.telefone)
      tempErrors.telefone = "El número de teléfono es obligatorio";
    if (!formData.endereco) tempErrors.endereco = "La dirección es obligatoria";
    if (!formData.cidade) tempErrors.cidade = "La ciudad es obligatoria";
    if (!formData.estado)
      tempErrors.estado = "El estado/provincia/región es obligatorio";
    if (!formData.paisEndereco)
      tempErrors.paisEndereco = "El país es obligatorio";
    if (!formData.cep) tempErrors.cep = "El código postal es obligatorio";
    if (!formData.paisDocumento)
      tempErrors.paisDocumento = "El país del documento es obligatorio";
    if (!formData.tipoDocumento)
      tempErrors.tipoDocumento = "El tipo de documento es obligatorio";
    if (!formData.tipoComprovante)
      tempErrors.tipoComprovante = "El tipo de comprobante es obligatorio";
    if (!documentos.frente)
      tempErrors.frente =
        "El documento de identificación (frente) es obligatorio";
    if (!documentos.verso)
      tempErrors.verso =
        "El documento de identificación (verso) es obligatorio";
    if (!documentos.comprovante)
      tempErrors.comprovante = "El comprobante de domicilio es obligatorio";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if(!politicaChecked){
      toast.error("Por favor, acepte la política de privacidad.");
      return;
    }
    setLoading(true);
    if (validateForm()) {
      const submitData = new FormData();

      // Append form data
      Object.keys(formData).forEach((key) => {
        submitData.append(key, formData[key]);
      });

      // Append documents
      Object.keys(documentos).forEach((key) => {
        if (documentos[key]) {
          submitData.append(key, documentos[key]);
        }
      });

      const extensaoFrente = documentos.frente.name.split(".").pop();
      const extensaoVerso = documentos.verso.name.split(".").pop();
      const extensaoComprovante = documentos.comprovante.name.split(".").pop();

      //incluir as extensoes dos arquivos no submitData
      submitData.append("extensaoFrente", extensaoFrente);
      submitData.append("extensaoVerso", extensaoVerso);
      submitData.append("extensaoComprovante", extensaoComprovante);

      try {
        const api = new APIClient();
        const response = await api.post("/kyc-onboarding", submitData);
        if (response.sucesso) {
          toast.success("¡Formulario enviado con éxito!");
          setFinished(true);
        } else {
          toast.error(
            response.mensagem || "Ocurrió un error al enviar el formulario."
          );
        }
      } catch (error) {
        toast.error("Ocurrió un error al enviar el formulario.");
      }
    } else {
      toast.error("Por favor, complete todos los campos obligatorios.");
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="53" />
                    </Link>
                  </div>
                  <p className="mt-3 fs-15 fw-medium">
                    Su puerta de entrada al futuro financiero.
                  </p>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={10} lg={8}>
                <Card className="mt-4">
                  {finished ? (
                    <CardBody className="p-4">
                      <div
                        className={`rounded-circle p-3 mb-2 bg-success`}
                        style={{
                          width: "100px",
                          height: "100px",
                          margin: "0 auto",
                        }}
                      >
                        <FeatherIcon
                          icon="check-circle"
                          className="text-white"
                          size={70}
                        />
                      </div>
                      <CardText tag="h4" className="font-weight-bold mt-4">
                        ¡Sus datos han sido enviados con éxito! Espere el
                        contacto de nuestro equipo.
                      </CardText>
                    </CardBody>
                  ) : (
                    <CardBody className="p-4">
                      <div className="text-center mt-2">
                        <h5 className="text-secondary">
                          Onboarding Persona Física
                        </h5>
                        <p className="text-muted">
                          Complete su información personal
                        </p>
                      </div>
                      <div className="p-2 mt-4">
                        <Form
                          onSubmit={onSubmit}
                          className="needs-validation"
                          encType="multipart/form-data"
                        >
                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="nome" className="form-label">
                                  Nombre
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="nome"
                                  name="nome"
                                  placeholder="Ingrese su nombre"
                                  value={formData.nome}
                                  onChange={handleInputChange}
                                  invalid={!!errors.nome}
                                />
                                {errors.nome && (
                                  <FormFeedback>{errors.nome}</FormFeedback>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="sobrenome"
                                  className="form-label"
                                >
                                  Apellido
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="sobrenome"
                                  name="sobrenome"
                                  placeholder="Ingrese su apellido"
                                  value={formData.sobrenome}
                                  onChange={handleInputChange}
                                  invalid={!!errors.sobrenome}
                                />
                                {errors.sobrenome && (
                                  <FormFeedback>
                                    {errors.sobrenome}
                                  </FormFeedback>
                                )}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="dataNascimento"
                                  className="form-label"
                                >
                                  Fecha de Nacimiento
                                </Label>
                                <Input
                                  type="date"
                                  className="form-control"
                                  id="dataNascimento"
                                  name="dataNascimento"
                                  value={formData.dataNascimento}
                                  onChange={handleInputChange}
                                  invalid={!!errors.dataNascimento}
                                />
                                {errors.dataNascimento && (
                                  <FormFeedback>
                                    {errors.dataNascimento}
                                  </FormFeedback>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="email" className="form-label">
                                  Correo Electrónico
                                </Label>
                                <Input
                                  type="email"
                                  className="form-control"
                                  id="email"
                                  name="email"
                                  placeholder="Ingrese su dirección de correo electrónico"
                                  value={formData.email}
                                  onChange={handleInputChange}
                                  invalid={!!errors.email}
                                />
                                {errors.email && (
                                  <FormFeedback>{errors.email}</FormFeedback>
                                )}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="paisFiscal"
                                  className="form-label"
                                >
                                  País del Documento
                                </Label>
                                <Input
                                  type="select"
                                  className="form-control"
                                  id="paisFiscal"
                                  name="paisFiscal"
                                  value={formData.paisFiscal}
                                  onChange={handleInputChange}
                                  invalid={!!errors.paisFiscal}
                                >
                                  <option value="">Seleccione el país</option>
                                  <option value="Brasil">Brasil</option>
                                  <option value="Argentina">Argentina</option>
                                </Input>
                                {errors.paisFiscal && (
                                  <FormFeedback>
                                    {errors.paisFiscal}
                                  </FormFeedback>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="documentoFiscal"
                                  className="form-label"
                                >
                                  Documento Fiscal
                                </Label>
                                <InputMask
                                  mask={
                                    formData.paisFiscal === "Brasil"
                                      ? "999.999.999-99"
                                      : "99.999.999"
                                  }
                                  value={formData.documentoFiscal}
                                  onChange={handleInputChange}
                                  className={`form-control ${
                                    errors.documentoFiscal ? "is-invalid" : ""
                                  }`}
                                  placeholder={
                                    formData.paisFiscal === "Brasil"
                                      ? "000.000.000-00"
                                      : "00.000.000"
                                  }
                                >
                                  {(inputProps) => (
                                    <Input
                                      {...inputProps}
                                      type="text"
                                      name="documentoFiscal"
                                    />
                                  )}
                                </InputMask>
                                {errors.documentoFiscal && (
                                  <FormFeedback>
                                    {errors.documentoFiscal}
                                  </FormFeedback>
                                )}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="paisTelefone"
                                  className="form-label"
                                >
                                  País del Teléfono
                                </Label>
                                <Input
                                  type="select"
                                  className="form-control"
                                  id="paisTelefone"
                                  name="paisTelefone"
                                  value={formData.paisTelefone}
                                  onChange={handleInputChange}
                                  invalid={!!errors.paisTelefone}
                                >
                                  <option value="">Seleccione el país</option>
                                  <option value="Brasil">Brasil</option>
                                  <option value="Argentina">Argentina</option>
                                </Input>
                                {errors.paisTelefone && (
                                  <FormFeedback>
                                    {errors.paisTelefone}
                                  </FormFeedback>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="telefone"
                                  className="form-label"
                                >
                                  Número de Teléfono
                                </Label>
                                <InputMask
                                  mask={
                                    formData.paisTelefone === "Brasil"
                                      ? "+55 (99) 99999-9999"
                                      : "+54 (99) 9999-9999"
                                  }
                                  value={formData.telefone}
                                  onChange={handleInputChange}
                                  className={`form-control ${
                                    errors.telefone ? "is-invalid" : ""
                                  }`}
                                  placeholder={
                                    formData.paisTelefone === "Brasil"
                                      ? "+55 (11) 98765-4321"
                                      : "+54 (11) 1234-5678"
                                  }
                                >
                                  {(inputProps) => (
                                    <Input
                                      {...inputProps}
                                      type="text"
                                      name="telefone"
                                    />
                                  )}
                                </InputMask>
                                {errors.telefone && (
                                  <FormFeedback>{errors.telefone}</FormFeedback>
                                )}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={12}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="endereco"
                                  className="form-label"
                                >
                                  Dirección
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="endereco"
                                  name="endereco"
                                  placeholder="Ingrese su dirección"
                                  value={formData.endereco}
                                  onChange={handleInputChange}
                                  invalid={!!errors.endereco}
                                />
                                {errors.endereco && (
                                  <FormFeedback>{errors.endereco}</FormFeedback>
                                )}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="cidade" className="form-label">
                                  Ciudad
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="cidade"
                                  name="cidade"
                                  placeholder="Ingrese su ciudad"
                                  value={formData.cidade}
                                  onChange={handleInputChange}
                                  invalid={!!errors.cidade}
                                />
                                {errors.cidade && (
                                  <FormFeedback>{errors.cidade}</FormFeedback>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="estado" className="form-label">
                                  Estado/Provincia/Región
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="estado"
                                  name="estado"
                                  placeholder="Ingrese su estado/provincia/región"
                                  value={formData.estado}
                                  onChange={handleInputChange}
                                  invalid={!!errors.estado}
                                />
                                {errors.estado && (
                                  <FormFeedback>{errors.estado}</FormFeedback>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="paisEndereco"
                                  className="form-label"
                                >
                                  País
                                </Label>
                                <Input
                                  type="select"
                                  className="form-control"
                                  id="paisEndereco"
                                  name="paisEndereco"
                                  value={formData.paisEndereco}
                                  onChange={handleInputChange}
                                  invalid={!!errors.paisEndereco}
                                >
                                  <option value="">Seleccione el país</option>
                                  <option value="Brasil">Brasil</option>
                                  <option value="Argentina">Argentina</option>
                                </Input>
                                {errors.paisEndereco && (
                                  <FormFeedback>
                                    {errors.paisEndereco}
                                  </FormFeedback>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="cep" className="form-label">
                                  Código Postal
                                </Label>
                                <InputMask
                                  mask={
                                    formData.paisEndereco === "Brasil"
                                      ? "99999-999"
                                      : "9999"
                                  }
                                  value={formData.cep}
                                  onChange={handleInputChange}
                                  className={`form-control ${
                                    errors.cep ? "is-invalid" : ""
                                  }`}
                                  placeholder={
                                    formData.paisEndereco === "Brasil"
                                      ? "00000-000"
                                      : "0000"
                                  }
                                >
                                  {(inputProps) => (
                                    <Input
                                      {...inputProps}
                                      type="text"
                                      name="cep"
                                    />
                                  )}
                                </InputMask>
                                {errors.cep && (
                                  <FormFeedback>{errors.cep}</FormFeedback>
                                )}
                              </div>
                            </Col>
                          </Row>

                          <h5 className="mt-4">Carga de Documentos</h5>
                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="paisDocumento"
                                  className="form-label"
                                >
                                  País del Documento
                                </Label>
                                <Input
                                  type="select"
                                  className="form-control"
                                  id="paisDocumento"
                                  name="paisDocumento"
                                  value={formData.paisDocumento}
                                  onChange={handleInputChange}
                                  invalid={!!errors.paisDocumento}
                                >
                                  <option value="">Seleccione el país</option>
                                  <option value="Brasil">Brasil</option>
                                  <option value="Argentina">Argentina</option>
                                </Input>
                                {errors.paisDocumento && (
                                  <FormFeedback>
                                    {errors.paisDocumento}
                                  </FormFeedback>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="tipoDocumento"
                                  className="form-label"
                                >
                                  Tipo de Documento
                                </Label>
                                <Input
                                  type="select"
                                  className="form-control"
                                  id="tipoDocumento"
                                  name="tipoDocumento"
                                  value={formData.tipoDocumento}
                                  onChange={handleInputChange}
                                  invalid={!!errors.tipoDocumento}
                                >
                                  <option value="">Seleccione el tipo</option>
                                  {formData.paisDocumento === "Brasil" && (
                                    <>
                                      <option value="rg">RG</option>
                                      <option value="cnh">CNH</option>
                                    </>
                                  )}
                                  {formData.paisDocumento === "Argentina" && (
                                    <>
                                      <option value="dni">DNI</option>
                                      <option value="licencia">
                                        Licencia de Conducir
                                      </option>
                                    </>
                                  )}
                                  <option value="passaporte">Pasaporte</option>
                                </Input>
                                {errors.tipoDocumento && (
                                  <FormFeedback>
                                    {errors.tipoDocumento}
                                  </FormFeedback>
                                )}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="frente" className="form-label">
                                  Documento de Identificación - Anverso
                                </Label>
                                <Input
                                  type="file"
                                  className="form-control"
                                  id="frente"
                                  onChange={(e) =>
                                    handleFileChange(e, "frente")
                                  }
                                  invalid={!!errors.frente}
                                />
                                {errors.frente && (
                                  <FormFeedback>{errors.frente}</FormFeedback>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="verso" className="form-label">
                                  Documento de Identificación - Reverso
                                </Label>
                                <Input
                                  type="file"
                                  className="form-control"
                                  id="verso"
                                  onChange={(e) => handleFileChange(e, "verso")}
                                  invalid={!!errors.verso}
                                />
                                {errors.verso && (
                                  <FormFeedback>{errors.verso}</FormFeedback>
                                )}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="tipoComprovante"
                                  className="form-label"
                                >
                                  Tipo de Comprobante
                                </Label>
                                <Input
                                  type="select"
                                  className="form-control"
                                  id="tipoComprovante"
                                  name="tipoComprovante"
                                  value={formData.tipoComprovante}
                                  onChange={handleInputChange}
                                  invalid={!!errors.tipoComprovante}
                                >
                                  <option value="">Seleccione el tipo</option>
                                  <option value="conta-servico">
                                    Factura de Servicio
                                  </option>
                                  <option value="outros">Otros</option>
                                </Input>
                                {errors.tipoComprovante && (
                                  <FormFeedback>
                                    {errors.tipoComprovante}
                                  </FormFeedback>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="comprovante"
                                  className="form-label"
                                >
                                  Comprobante de Domicilio
                                </Label>
                                <Input
                                  type="file"
                                  className="form-control"
                                  id="comprovante"
                                  onChange={(e) =>
                                    handleFileChange(e, "comprovante")
                                  }
                                  invalid={!!errors.comprovante}
                                />
                                {errors.comprovante && (
                                  <FormFeedback>
                                    {errors.comprovante}
                                  </FormFeedback>
                                )}
                              </div>
                            </Col>
                          </Row>

                          <Row className="mt-4 justify-content-center">
                            <Col md={12}>
                              <div className="form-check">
                                <Input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="aceptarPolitica"
                                  checked={politicaChecked}
                                  onChange={(e) =>
                                    setPoliticaChecked(e.target.checked)
                                  }
                                  required
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="aceptarPolitica"
                                >
                                  Acepto la{" "}
                                  <Link to="https://nautt.finance/politica-de-privacidad/" target="_blank" className="text-success">
                                    política de privacidad
                                  </Link>
                                </Label>
                              </div>
                            </Col>
                          </Row>

                          <Row className="mt-4 justify-content-center">
                            <Col md={4}>
                              <Button
                                color="success"
                                className="w-100"
                                type="submit"
                                disabled={loading}
                              >
                                {loading ? (
                                  <Spinner size="sm" color="light" />
                                ) : (
                                  "Enviar Información"
                                )}
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </CardBody>
                  )}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default RegisterKyc;
