import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Form,
  Spinner,
} from "reactstrap";
import { toast } from "react-toastify";
import { APIClient, getLoggedinUser } from "../../helpers/api_helper";
import InputMask from "react-input-mask";
import { formatEmail } from "../../Components/FunctionsRepository";
import CarteiraClienteTable from "./CarteiraClienteTable";

import avatar from "../../assets/images/logo-sm-1.png";

const UserProfile = () => {
  document.title = "Perfil del Usuario | Nautt Finance";

  const [userData, setUserData] = useState({
    id: "",
    nome: "",
    username: "",
    email: "",
    telefone: "",
    pais_telefone: "BR",
  });

  const [loading, setLoading] = useState(false);

  const fetchUserData = async (userId) => {
    setLoading(true);
    const apiClient = new APIClient();
    const response = await apiClient.get("/usuarios", { user_id: userId });
    setLoading(false);

    if (response.sucesso) {
      const user = response.dados;
      setUserData({
        id: user.id,
        nome: user.nome,
        username: user.username,
        email: user.email,
        telefone: user.telefone || "",
        pais_telefone: user.pais_telefone || "BR",
      });
    } else {
      toast.error(response.mensagem || "Error al cargar los datos del usuario.");
    }
  };

  useEffect(() => {
    const initializeUserData = async () => {
      const loggedInUser = await getLoggedinUser();
      if (loggedInUser && loggedInUser.id) {
        fetchUserData(loggedInUser.id);
      }
    };

    initializeUserData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setUserData({
        ...userData,
        [name]: formatEmail(value),
      });
    } else {
      setUserData({
        ...userData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const apiClient = new APIClient();
    const response = await apiClient.put("/usuarios", {
      user_id: userData.id,
      email: userData.email,
      telefone: userData.telefone.replace(/\D/g, ""),
      pais_telefone: userData.pais_telefone,
    });

    setLoading(false);

    if (response.sucesso) {
      toast.success("¡Perfil actualizado con éxito!");
    } else {
      toast.error(response.mensagem || "Error al actualizar el perfil.");
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="mx-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{userData.nome}</h5>
                        <p className="mb-1">Correo electrónico: {userData.email}</p>
                        <p className="mb-0">ID: #{userData.id}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col lg="12">
              <h4 className="card-title mb-4">Editar Perfil</h4>
              <Card>
                <CardBody>
                  <Form className="form-horizontal" onSubmit={handleSubmit}>
                    <Row className="mb-3">
                      <Col md="6">
                        <Label className="form-label">Nombre de Usuario</Label>
                        <Input
                          name="username"
                          className="form-control"
                          placeholder="Nombre de Usuario"
                          type="text"
                          value={userData.username}
                          readOnly
                        />
                      </Col>
                      <Col md="6">
                        <Label className="form-label">Correo electrónico</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Correo electrónico"
                          type="email"
                          value={userData.email}
                          onChange={handleInputChange}
                          maxLength={150}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md="6">
                        <Label className="form-label">País del Teléfono</Label>
                        <Input
                          type="select"
                          name="pais_telefone"
                          value={userData.pais_telefone}
                          onChange={handleInputChange}
                          className="form-control"
                        >
                          <option value="BR">Brasil (+55)</option>
                          <option value="AR">Argentina (+54)</option>
                        </Input>
                      </Col>
                      <Col md="6">
                        <Label className="form-label">Teléfono</Label>
                        <InputMask
                          mask={
                            userData.pais_telefone === "BR"
                              ? "(99) 99999-9999"
                              : "999 999-9999"
                          }
                          placeholder={
                            userData.pais_telefone === "BR"
                              ? "(99) 99999-9999"
                              : "999 999-9999"
                          }
                          value={userData.telefone}
                          onChange={handleInputChange}
                        >
                          {(inputProps) => (
                            <Input
                              type="text"
                              name="telefone"
                              className="form-control"
                              {...inputProps}
                            />
                          )}
                        </InputMask>
                      </Col>
                    </Row>
                    <div className="text-center mt-4">
                      <Button type="submit" color="primary" disabled={loading}>
                        {loading ? <Spinner size="sm" /> : "Actualizar Perfil"}
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col lg="12">
              <h4 className="card-title mb-4 mt-3">Wallets del cliente</h4>
              <CarteiraClienteTable userId={userData.id} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserProfile;
