import React, { useState, useEffect, useCallback } from "react";
import { Badge, Button, Card, CardBody, CardHeader } from "reactstrap";
import { APIClient } from "../../helpers/api_helper";
import ModalApagarCarteira from "./ModalApagarCarteira";
import ModalNovaCarteira from "./ModalNovaCarteira";

const CarteiraClienteTable = ({ userId }) => {
  const [carteiras, setCarteiras] = useState([]);
  const [modalApagar, setModalApagar] = useState(false);
  const [modalNova, setModalNova] = useState(false);
  const [selectedCarteira, setSelectedCarteira] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const fetchCarteiras = useCallback(async () => {
    const apiClient = new APIClient();
    const response = await apiClient.get("/carteira-cliente", {
      user_id: userId,
    });
    if (response.sucesso) {
      setCarteiras(response.dados);
    } else {
      console.error("Error al buscar wallets:", response.mensagem);
    }
    setRefresh(false);
  }, [userId]);

  useEffect(() => {
    fetchCarteiras();
  }, [fetchCarteiras, refresh]);

  const getBadgeColor = (tipo) => {
    return tipo.toLowerCase() === "comum" ? "primary" : "success";
  };

  const handleDeleteClick = (carteira) => {
    setSelectedCarteira(carteira);
    setModalApagar(true);
  };

  const handleNovaCarteiraClick = () => {
    setModalNova(true);
  };

  return (
    <Card>
      <CardHeader className="align-items-center d-flex">
        <h4 className="card-title mb-0 flex-grow-1">Wallets del cliente</h4>
        <div className="flex-shrink-0">
          <Button color="soft-secondary" onClick={handleNovaCarteiraClick}>
            Nueva Wallet
          </Button>
        </div>
      </CardHeader>
      <CardBody className="p-4">
        <div className="live-preview">
          <div className="table-responsive table-card">
            <table className="table align-middle table-nowrap table-striped-columns mb-1">
              <thead className="table-light">
                <tr>
                  <th>Wallet</th>
                  <th>Nombre de la Red</th>
                  <th>Tipo</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {carteiras.map((carteira) => (
                  <tr key={carteira.id}>
                    <td>{carteira.carteira}</td>
                    <td>{carteira.rede.nome}</td>
                    <td>
                      <Badge color={getBadgeColor(carteira.tipo)}>
                        {carteira.tipo.charAt(0).toUpperCase() +
                          carteira.tipo.slice(1)}
                      </Badge>
                    </td>
                    <td>
                      <Button
                        color="danger"
                        size="sm"
                        onClick={() => handleDeleteClick(carteira)}
                      >
                        <i className="ri-delete-bin-line"></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </CardBody>
      <ModalApagarCarteira
        modal={modalApagar}
        setModal={setModalApagar}
        setRefresh={setRefresh}
        carteira={selectedCarteira}
      />
      <ModalNovaCarteira
        modal={modalNova}
        setModal={setModalNova}
        userId={userId}
        setRefresh={setRefresh}
      />
    </Card>
  );
};

export default CarteiraClienteTable;
