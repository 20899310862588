import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import Widget from "./Widgets";
import RecentOrders from "./RecentOrders";
import StoreVisits from "./StoreVisits";
import TopSellers from "./TopSellers";
import Revenue from "./Revenue";
import { getLoggedinUser } from "../../helpers/api_helper";

const DashboardEcommerce = () => {
  document.title = "Dashboard | Nautt Finance";
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const getData = async () => {
      const user = await getLoggedinUser();
      setUserData(user);
    };
    getData();
  }, []);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Row>
                  <Widget />
                </Row>
                {userData?.role === "lojista" ? (
                  <Row>
                    <StoreVisits />
                    <RecentOrders />
                  </Row>
                ) : (
                  <Row>
                    <TopSellers />
                    <Revenue />
                  </Row>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardEcommerce;
