import React, { useState, useEffect, useCallback } from "react";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Button } from "reactstrap";
import { toast } from "react-toastify";
import { APIClient } from "../../helpers/api_helper";
import ModalSaque from "./ModalSaque";
const apiClient = new APIClient();

const Widgets = () => {
  const [ecomWidgets, setEcomWidgets] = useState([]);
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);

  const getWidgetsData = useCallback(async () => {
    const response = await apiClient.get("/widgets", {});
    if (response.sucesso) {
      const dados = response.dados;
      let widgets = [
        {
          id: 1,
          cardColor: "primary",
          label: "Dólar / Real Brasileño",
          badge: "ri-arrow-right-up-line",
          badgeClass: "success",
          percentage: "+16.24",
          counter: "0",
          link: "Ver ganancias netas",
          bgcolor: "success",
          icon: "bx bx-dollar-circle",
          decimals: 2,
          prefix: "R$ ",
        },
        {
          id: 2,
          cardColor: "success",
          label: "Dólar / Peso Argentino",
          badge: "ri-arrow-right-up-line",
          badgeClass: "success",
          percentage: "+29.08",
          counter: "0",
          bgcolor: "secondary",
          icon: "bx bx-dollar-circle",
          decimals: 2,
          suffix: " ARS",
        },
        {
          id: 3,
          cardColor: "info",
          label: "Puntos Nautt",
          badgeClass: "muted",
          percentage: "+0.00",
          counter: "0",
          bgcolor: "warning",
          icon: "bx bx-wallet",
          decimals: 2,
        },
        {
          id: 4,
          cardColor: "info",
          label: "Cartera (USD)",
          badgeClass: "muted",
          percentage: "+0.00",
          counter: "0",
          bgcolor: "primary",
          icon: "bx bx-wallet",
          decimals: 2,
          prefix: "$ ",
        },
      ];
      widgets[0].counter = dados.brl;
      widgets[1].counter = dados.ars;
      widgets[2].counter = dados.nautt_pontos;
      widgets[3].counter = dados.caixa_usd;
      setEcomWidgets(widgets);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await getWidgetsData();
    };
    if (ecomWidgets.length === 0) fetchData();
  }, [ecomWidgets]);

  return (
    <React.Fragment>
      {ecomWidgets.map((item, key) => (
        <Col xl={3} md={6} key={key}>
          <Card className="card-animate">
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    {item.label}
                  </p>
                </div>
                <div className="flex-shrink-0">
                  {item.label === "Cartera (USD)" && (
                    <Link className="text-warning" onClick={toggleModal}>
                      Retirar fondos
                    </Link>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                    <span className="counter-value" data-target="559.25">
                      <CountUp
                        start={0}
                        prefix={item.prefix}
                        suffix={item.suffix}
                        separator={item.separator}
                        end={item.counter}
                        decimals={item.decimals}
                        duration={4}
                      />
                    </span>
                  </h4>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span
                    className={"avatar-title rounded fs-3 bg-" + item.bgcolor}
                  >
                    <i className={`${item.icon}`}></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
      <ModalSaque modal={modal} toggle={toggleModal} />
    </React.Fragment>
  );
};

export default Widgets;
