import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getLoggedinUser } from "../helpers/api_helper";
import { label } from "yet-another-react-lightbox";

const Navdata = () => {
  const history = useNavigate();
  const [userData, setUserData] = useState({});
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [iscurrentState, setIscurrentState] = useState("Dashboard");
  const [isGerarQrCode, setIsGerarQrCode] = useState("GerarOrdemPix");
  const [isOrdensPix, setIsOrdensPix] = useState("OrdensPix");
  const [isUsuarios, setIsUsuarios] = useState("Usuarios");
  const [isVerificacoes, setIsVerificacoes] = useState("Verificacoes");
  const [isPdv, setIsPdv] = useState("PontosVenda");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    const getData = async () => {
      const user = await getLoggedinUser();
      setUserData(user);
    };
    getData();
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "GerarOrdemPix") {
      setIsGerarQrCode(false);
    }
    if (iscurrentState !== "OrdensPix") {
      setIsOrdensPix(false);
    }
    if (iscurrentState !== "Usuarios") {
      setIsUsuarios(false);
    }
    if (iscurrentState !== "Verificacoes") {
      setIsVerificacoes(false);
    }
    if (iscurrentState !== "PontosVenda") {
      setIsPdv(false);
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isGerarQrCode,
    isOrdensPix,
    isUsuarios,
    isVerificacoes,
    isPdv,
  ]);

  const dashboardAdmin = {
    id: "dashboard",
    label: "Dashboard",
    icon: "mdi mdi-speedometer",
    link: "/dashboard",
    stateVariables: isDashboard,
    click: function (e) {
      e.preventDefault();
      setIsDashboard(!isDashboard);
      setIscurrentState("Dashboard");
      updateIconSidebar(e);
    },
  };

  const verificacoes = {
    id: "verificacoes",
    label: "Verificaciones",
    icon: "mdi mdi-account-check",
    link: "/#",
    stateVariables: isVerificacoes,
    click: function (e) {
      e.preventDefault();
      setIsVerificacoes(!isVerificacoes);
      setIscurrentState("Verificacoes");
      updateIconSidebar(e);
    },
    subItems: [
      {
        id: "verificacoes-kyb",
        label: "Verificaciones KYB",
        link: "/verificacoes-kyb",
        parentId: "verificacoes",
      },
      {
        id: "verificacoes-kyc",
        label: "Verificaciones KYC",
        link: "/verificacoes-kyc",
        parentId: "verificacoes",
      },
    ],
  }

  const ordensPix = [
    {
      id: "gerar-ordem-pix",
      label: "Generar Orden",
      icon: "mdi mdi-qrcode",
      link: "/gerar-ordem-pix",
      stateVariables: isGerarQrCode,
      click: function (e) {
        e.preventDefault();
        setIsGerarQrCode(!isGerarQrCode);
        setIscurrentState("GerarOrdemPix");
        updateIconSidebar(e);
      },
    },
    {
      id: "ordens-pix",
      label: "Órdenes",
      icon: "mdi mdi-currency-usd",
      link: "/ordens-pix",
      stateVariables: isOrdensPix,
      click: function (e) {
        e.preventDefault();
        setIsOrdensPix(!isOrdensPix);
        setIscurrentState("OrdensPix");
        updateIconSidebar(e);
      },
    },
  ];

  const usuarios = {
    id: "usuarios",
    label: "Usuarios",
    icon: "mdi mdi-account",
    link: "/usuarios",
    stateVariables: isUsuarios,
    click: function (e) {
      e.preventDefault();
      setIsUsuarios(!isUsuarios);
      setIscurrentState("Usuarios");
      updateIconSidebar(e);
    },
  };

  const pdv = {
    id: "pontos-venda",
    label: "Puntos de Venta",
    icon: "mdi mdi-store",
    link: "/pontos-venda",
    stateVariables: isPdv,
    click: function (e) {
      e.preventDefault();
      setIsUsuarios(!isPdv);
      setIscurrentState("PontosVenda");
      updateIconSidebar(e);
    },
  };

  const menuItems =
    userData?.role === "admin"
      ? [
          {
            label: "Menú",
            isHeader: true,
          },
          dashboardAdmin,
          ...ordensPix,
          verificacoes,
          usuarios,
        ]
      : userData?.role === "lojista"
      ? [
          {
            label: "Menú",
            isHeader: true,
          },
          dashboardAdmin,
          ...ordensPix,
          pdv,
        ]
      : userData?.role === "atendente"
      ? [
          {
            label: "Menú",
            isHeader: true,
          },
          ...ordensPix,
        ]
      : [
          {
            label: "Menú",
            isHeader: true,
          },
          // dashboardAdmin,
          ...ordensPix,
          usuarios,
        ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;

